import cx from 'classnames';
import type { PolymorphicComponentProps } from '@carvertical/ui';
import { SanityImage, type SanityImageProps } from './SanityImage';
import styles from './RoundedImage.module.scss';

type RoundedImageProps = PolymorphicComponentProps & {
  className?: string;
  imageClassName?: string;
  image: SanityImageProps['image'] | null;
} & Omit<SanityImageProps, 'image'>;

const RoundedImage = ({
  as: RenderAs = 'div',
  className,
  imageClassName,
  image,
  ...imageProps
}: RoundedImageProps) => (
  <RenderAs className={cx(styles.root, !image && styles.placeholder, className)}>
    {image && <SanityImage className={imageClassName} image={image} {...imageProps} />}
  </RenderAs>
);

export { RoundedImage };
