import { Text, type TextProps } from '@carvertical/ui';

type ArticleDateProps = {
  date: string;
} & TextProps;

const ArticleDate = ({ date, ...props }: ArticleDateProps) => (
  <Text variant="xs" textColor="darkSecondary" {...props}>
    {date}
  </Text>
);

export { ArticleDate };
export type { ArticleDateProps };
