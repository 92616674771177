import { pick, reduce, mapValues, compact } from 'lodash';
import { getLocaleFromRouter } from 'utils/locale';
import { basePathsByLocale } from 'config-ts';
import { BLOG_LOCALE_OVERRIDES, LOCALIZED_SINGLETON_IDENTIFIER } from '../constants';
import type {
  Article,
  ArticleCategory,
  ArticleTag,
  BlogRouteProps,
  Paths,
  Singleton,
  Translation,
} from '../types';

const getLocalizedSingletonId = (singletonForPath: Pick<Singleton, 'baseId'>, locale: string) =>
  `${singletonForPath.baseId}${LOCALIZED_SINGLETON_IDENTIFIER}${locale}`;

const getBlogLocale = ({
  country,
  language,
}: Pick<BlogRouteProps['params'], 'country' | 'language'>) => {
  const routeLocale = getLocaleFromRouter({
    pathname: undefined,
    query: {
      country,
      language,
    },
  }).toLowerCase();

  return BLOG_LOCALE_OVERRIDES[routeLocale] || routeLocale;
};

const mergeCategoriesArticles = (categories: ArticleCategory[]) =>
  categories.reduce<Article[]>((articles, current) => {
    if (current.articles) {
      articles.push(...current.articles);
    }
    return articles;
  }, []);

const mergeTagArticles = (tags: ArticleTag[]) =>
  tags.reduce<Article[]>((articles, current) => {
    if (current.articles) {
      articles.push(...current.articles);
    }
    return articles;
  }, []);

const extractFirstEntry = <TEntry>(entries: TEntry[]) => entries.filter(Boolean)[0];

const getAlternativePaths = (translations?: (Translation | null)[]): Paths | null => {
  if (!translations) {
    return null;
  }

  const alternativePaths = localizeAlternativePaths(prepareAlternativePaths(compact(translations)));

  return pick(alternativePaths, Object.keys(alternativePaths).sort());
};

function prepareAlternativePaths(translations: Translation[]) {
  return reduce(
    translations,
    (paths: Paths, { locale, internalLink }) => {
      if (internalLink?.pathname) {
        paths[locale] = internalLink.pathname;
      }
      return paths;
    },
    {},
  );
}

function localizeAlternativePaths(alternativePaths: Paths) {
  return mapValues(
    alternativePaths,
    (path, locale) => `${basePathsByLocale[locale]}${path.slice(1)}`,
  );
}

export {
  getBlogLocale,
  getLocalizedSingletonId,
  extractFirstEntry,
  getAlternativePaths,
  mergeCategoriesArticles,
  mergeTagArticles,
};
