import NextImage, { type ImageProps } from 'next/image';
import { useNextSanityImage } from 'next-sanity-image';
import { sanityClient } from '../../api';
import type { Image } from '../../types';

type SanityImageProps = {
  image: Image;
  height?: number | 'auto';
  width?: number | '100%';
} & Omit<ImageProps, 'src' | 'alt' | 'width' | 'height'>;

const SanityImage = ({ height = 'auto', image, width, ...props }: SanityImageProps) => {
  const imageProps = useNextSanityImage(sanityClient, image);

  return (
    <NextImage
      {...imageProps}
      {...props}
      style={{ height, ...(width ? { width } : { maxWidth: '100%' }) }}
      alt={image.alt || image.caption || ''}
      {...(image.asset.lqip && {
        placeholder: 'blur',
        blurDataURL: image.asset.lqip,
      })}
    />
  );
};

export { SanityImage };
export type { SanityImageProps };
