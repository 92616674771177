import cx from 'classnames';
import type { ArticleAuthor } from 'modules/content/types';
import { SanityImage } from '../common/SanityImage';
import styles from './AuthorImage.module.scss';

type AuthorImageProps = Pick<ArticleAuthor, 'image' | 'name'> & {
  size: number;
  className?: string;
};

const AuthorImage = ({ image, name, className, size }: AuthorImageProps) => (
  <div className={cx(styles.root, className)} style={{ '--size': `${size}px` }}>
    <SanityImage width="100%" height="auto" image={{ ...image, alt: name }} />
  </div>
);

export { AuthorImage };
export type { AuthorImageProps };
