import { PublishStatus } from '../types';

const DOC_ID_FILTER = '_id == $docId';

const LOCALE_FILTER = 'locale == $requestLocale';

const LOCALES_ARRAY_FILTER = 'locale in $requestLocale';

const PUBLIC_FILTER = `publishStatus == '${PublishStatus.public}'`;

const ORDER_BY_PUBLISH_DATE_DESC = 'order(publishedAt desc)';

export {
  LOCALE_FILTER,
  PUBLIC_FILTER,
  DOC_ID_FILTER,
  LOCALES_ARRAY_FILTER,
  ORDER_BY_PUBLISH_DATE_DESC,
};
