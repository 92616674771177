import { groq, type QueryParams } from 'next-sanity';
import { omitBy, isNil } from 'lodash';
import { SINGLETONS } from '../constants';
import { DOCUMENT_FOR_PATH_FRAGMENT } from '../groq';
import { DOC_ID_FILTER, PUBLIC_FILTER } from '../filters';
import type { GetCollectionProps } from '../types';
import { getLocalizedSingletonId } from './blog';

type BuildCollectionQueryProps = Omit<GetCollectionProps, 'locale' | 'docId'>;

const prepareQueryParams = ({
  extraParams = {},
  locale,
}: {
  extraParams?: QueryParams;
  locale: string;
}): QueryParams => {
  const singletonIds = Object.fromEntries(
    SINGLETONS.map((singleton) => [
      `${singleton.baseId}Id`,
      getLocalizedSingletonId(singleton, locale),
    ]),
  );

  const params = {
    docId: extraParams.docId ?? null,
    requestLocale: locale ?? null,
    ...singletonIds,
    ...extraParams,
  };

  return omitBy(params, isNil);
};

const prepareRouteQuery = (query: string) => {
  if (!query.trim().startsWith('"') && !query.trim().startsWith("'")) {
    throw new Error(
      'Route queries will be injected into a GROQ object, as such you must define only object properties in the top-level. Example: "page": *[...]',
    );
  }

  return groq`{
    ${query}
    ${query.trim().endsWith(',') ? '' : ','}
    "isSanityData": true,
    "locale": $requestLocale
  }`;
};

function buildCollectionQuery({ options, extraQuery }: BuildCollectionQueryProps) {
  const { entriesPerPage, entryQueryFragment, order, extraFilters } = options;

  const entriesFilterParts = [
    '_type in $entryDocTypes',
    `(${PUBLIC_FILTER})`,
    ...(extraFilters || []).map((filter) => `(${filter})`),
  ]
    .filter(Boolean)
    .join(' && ');

  return groq`
    "routeData": *[${DOC_ID_FILTER}][0] {
      ${DOCUMENT_FOR_PATH_FRAGMENT}
      ...
    },
    "pageNum": $pageNum,
    "entriesPerPage": ${entriesPerPage || 'null'},
    "totalEntriesCount": count(*[${entriesFilterParts}]._id),
    "entries": *[${entriesFilterParts}]
      | ${order || 'order(orderRank, _createdAt desc)'}
      ${entriesPerPage ? `[${entriesPerPage} * ($pageNum - 1)...${entriesPerPage} * $pageNum]` : ''}
      {
        _id,
        _type,
        ${entryQueryFragment || ''}
      },
    ${extraQuery}
  `;
}

export { buildCollectionQuery, prepareQueryParams, prepareRouteQuery };
