import { createClient } from 'next-sanity';
import { sanityConfig } from 'config';

const sanityClient = createClient({
  projectId: sanityConfig.projectId,
  dataset: sanityConfig.dataset,
  apiVersion: sanityConfig.apiVersion,
  useCdn: false,
});

export { sanityClient };
