import { BlogDocumentType, type CollectionQueryOptions } from '../types';

const CATEGORY_FILTER = `_type == "${BlogDocumentType.Category}"`;

const ARTICLE_FILTER = `_type == "${BlogDocumentType.Article}"`;

const TAG_FILTER = `_type == "${BlogDocumentType.Tag}"`;

const CATEGORY_KEY_FILTER = `${CATEGORY_FILTER} && key == $key`;

const TAG_TITLE_FILTER = `${TAG_FILTER} && title == $title`;

const blogIndexFilters = (
  locales: string[],
  filters: CollectionQueryOptions['extraFilters'] = [],
) => {
  const extraFilters: string[] = ['isDelisted != true', `locale in ["${locales.join('","')}"]`];

  filters.forEach((filter) => {
    extraFilters.push(filter);
  });

  return extraFilters;
};

export {
  blogIndexFilters,
  ARTICLE_FILTER,
  CATEGORY_KEY_FILTER,
  CATEGORY_FILTER,
  TAG_FILTER,
  TAG_TITLE_FILTER,
};
